import { Box, Container, Heading, Text } from 'atomic'
import React, { FC } from 'react'
import * as css from './PreFooter.css'
import Button, { ButtonType } from 'atomic/components/atoms/button/Button'
import { Link } from 'components/link/Link'
import { IllustrationType } from 'atomic/components/atoms/illustrations'
import Illustration from 'atomic/components/atoms/illustrations/Illustration'
import { useMediaQuery } from 'react-responsive'
import { ouTheme } from 'atomic/styles/theme.css'
import { BuilderProps, removeKeys } from 'components/builder_components/helpers/BuilderPropCleaner'

type ButtonItem = {
  text: string
  type: ButtonType
  link: string
  target: boolean
  size: 'l' | 'm' | 's'
}

type PreFooterProps = {
  illustration: IllustrationType
  illustrationHeight?: number
  headline: string
  text?: string
  button?: ButtonItem
} & BuilderProps

const PreFooter: FC<PreFooterProps> = ({ illustration, illustrationHeight, headline, text, button, ...props }) => {
  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })

  const isDesktopMobile = useMediaQuery({ maxWidth: ouTheme.breakpoints.desktop })

  return (
    <Box paddingY={'xxxl'} position={'relative'} style={{ width: '100%', height: '100%' }} {...cleanProps}>
      <Illustration
        name={illustration ?? 'diamond'}
        height={!isDesktopMobile ? illustrationHeight : illustrationHeight - 70}
        className={css.illustration}
      />
      <Container className={css.container}>
        <div />
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} gap={'m'}>
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} gap={'xs'}>
            <Heading as={'h3'} fontSize={'xl'} marginBottom={'none'} textAlign={'center'}>
              {headline}
            </Heading>
            <Text fontSize={'base'} fontWeight={'light'} marginBottom={'none'} textAlign={'center'}>
              {text}
            </Text>
          </Box>
          <Link href={button.link} target={button.target ? '_blank' : undefined} style={{ outline: 'none' }}>
            <Button variant={button.type} size={button.size} style={{ outline: 'none' }}>
              {button.text}
            </Button>
          </Link>
        </Box>
        <div />
      </Container>
    </Box>
  )
}

export default PreFooter
